<template>
  <div class="subscription-tabs">
    <el-tabs v-model="activeName">
      <el-tab-pane label="Subscription" name="Subscription">
        <div class="subscription-con">
          <p class="mb-8">
            Global Youth Pulses (GYP) operates on an annual subscription basis.
            To access our system and services, you must enroll in one of our
            annual plans, which determine the sections where you can publish
            your articles. Once subscribed, you can submit an unlimited number
            of articles during the one-year validity period. (Please note,
            publication is contingent upon editorial review.)
          </p>
          <table
            width="100%"
            border="1"
            align="center"
            cellspacing="0"
            cellpadding="0"
          >
            <tr>
              <th width="30%"></th>
              <th width="33%">Basic Subscription</th>
              <th width="33%">Advanced Subscription</th>
            </tr>
            <tr v-for="(item, index) in tableData" :key="index">
              <td>
                {{ item.name }}
              </td>
              <td>
                <image src="icon" mode="scaleToFill" />
                <i
                  v-if="!item.basic"
                  class="el-icon-success"
                  style="color: #eb7269"
                ></i>
                <span v-else>{{ item.basic }}</span>
              </td>
              <td>
                <i
                  v-if="!item.advanced"
                  class="el-icon-success"
                  style="color: #eb7269"
                ></i>
                <span v-else>{{ item.advanced }}</span>
              </td>
            </tr>
            <tr class="no-border">
              <td></td>
              <td>
                <el-button
                  v-if="
                    subscribeInfo.basicStatus && !subscribeInfo.advancedStatus
                  "
                  class="expire-btn"
                  >Expires: {{ subscribeInfo.basicExpireDate }}</el-button
                >

                <el-button
                  v-if="
                    !subscribeInfo.basicStatus && !subscribeInfo.advancedStatus
                  "
                  type="primary"
                  @click="handleSubscribe('GYP Basic')"
                  >Subscribe</el-button
                >
              </td>
              <td>
                <el-button
                  v-if="subscribeInfo.advancedStatus"
                  class="expire-btn"
                  >Expires: {{ subscribeInfo.advancedExpireDate }}</el-button
                >
                <el-button
                  v-else
                  type="primary"
                  @click="handleSubscribe('GYP Advanced')"
                  >{{
                    subscribeInfo.basicStatus && !subscribeInfo.advancedStatus
                      ? "Upgrade"
                      : "Subscribe"
                  }}
                </el-button>
              </td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Subscription Records" name="Subscription Records">
        <table
          width="100%"
          border="1"
          align="center"
          cellspacing="0"
          cellpadding="0"
        >
          <tr>
            <th width="25%">GYP Plan</th>
            <th width="25%">Effective Date</th>
            <th width="25%">Expiry Date</th>
            <th width="25%">Receipt</th>
          </tr>
          <tr v-for="(item, index) in recordData" :key="index">
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.effective_date }}
            </td>
            <td>
              {{ item.expire_date }}
            </td>
            <td>
              <el-button
                v-if="item.status !== '无需缴费'"
                @click="handleCheckClick(item.id)"
                type="text"
                size="small"
                class="check-btn"
                >Check</el-button
              >
            </td>
          </tr>
        </table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import icon from "../assets/images/icon-size.png";
import {
  getProjects,
  createStudentApplication,
  getStudentSubscribeList,
} from "../api/index";
import { getUserId } from "../utils/store";
import { padDateNumbers } from "../utils/date";
import { mapState } from "vuex";
export default {
  data() {
    return {
      icon: icon,
      activeName: "Subscription",
      tableData: [
        {
          name: "Campus Pulses",
          basic: "",
          advanced: "",
        },
        {
          name: "Next Wave Business Review",
          basic: "-",
          advanced: "",
        },
        {
          name: "Literature and Art Review",
          basic: "-",
          advanced: "",
        },
        {
          name: "Comparative Thinking",
          basic: "-",
          advanced: "",
        },
        {
          name: "Intellect in Words",
          basic: "-",
          advanced: "",
        },
        {
          name: "Apex Stage",
          basic: "-",
          advanced: "",
        },
        {
          name: "History Perspectives",
          basic: "-",
          advanced: "",
        },
        {
          name: `Professor's Academic Challenge`,
          basic: "-",
          advanced: "",
        },
        {
          name: "Editorial Services",
          basic: "",
          advanced: "",
        },
        {
          name: "Article Submissions",
          basic: "Unlimited",
          advanced: "Unlimited",
        },
      ],
      recordData: [],
      userID: getUserId(),
      projectObj: [],
    };
  },

  computed: {
    ...mapState(["subscribeInfo"]),
  },

  mounted() {
    this.getProjects();
    this.getStudentSubscribeList();
  },

  methods: {
    async getProjects() {
      const res = await getProjects();
      this.projectObj = res.data;
    },
    async createStudentApplication(project_id) {
      const res = await createStudentApplication(this.userID, project_id);
      const application_id = res.data.data._id.$id;
      this.$router.push({
        path: "order",
        query: {
          application_id,
        },
      });
    },
    handleSubscribe(type) {
      const _id = this.projectObj.filter(
        (i) => i.product_abbreciation === type
      )[0]._id.$id;
      this.createStudentApplication(_id);
    },
    async getStudentSubscribeList() {
      const res = await getStudentSubscribeList(getUserId());
      const { data, code } = res.data;
      if (code === 0) {
        this.recordData = data.map((item) => {
          const _date = new Date(item.application_payment_date);
          const _year = _date.getFullYear() + 1;
          const _month = _date.getMonth() + 1;
          const _day = _date.getDate();
          return {
            name: item.application_project,
            effective_date: item.application_payment_date,
            expire_date: padDateNumbers(_year, _month, _day),
            id: item._id.$id,
            status: item.application_status,
          };
        });
      }
    },
    handleCheckClick(id) {
      this.$router.push({
        path: "/receipt",
        query: {
          application_id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-tabs {
  background: #fff;
  padding: 20px;
  min-height: calc(100vh - 90px);
  .mb-8 {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  table {
    font-size: 14px;
  }
  table th {
    color: #eb7269;
    background: #fdf2f0;
    padding: 12px 0px;
    text-align: center;
  }
  table td {
    border: 1px solid #fdf2f0;
    border-top: none;
    padding: 10px 0px;
    text-align: center;
    span {
      display: block;
    }
  }
  .no-border {
    td {
      border: none;
    }
    /deep/ .el-button {
      border-radius: 0px;
      width: 170px;
      line-height: 12px;
    }
    .expire-btn {
      background-color: #fdf2f0;
      color: #eb7269;
      border: none;
    }
  }
}
.subscription-con {
  height: 80vh;
  overflow-y: scroll;
}
.check-btn {
  border: none;
  text-decoration: dashed;
}
</style>
