<template>
  <div class="home-container">
    <div class="article-list" v-if="!isSubscription">
      <template v-for="(item, index) in articleList">
        <baseCard v-bind="item" @refreshData="queryData" :key="index" />
      </template>
    </div>
    <div class="subscription-content" v-if="isSubscription">
      <subscription></subscription>
    </div>
    <div class="customer-info">
      <customerInfo />
    </div>
  </div>
</template>

<script>
import baseCard from "@/components/baseCard.vue";
import customerInfo from "@/components/customerInfo.vue";
import subscription from "../components/subscription.vue";
import { getAllArticles } from "../api/index";
import { getUserId } from "../utils/store";
import { mapActions } from "vuex";
export default {
  name: "home",

  components: {
    baseCard,
    customerInfo,
    subscription,
  },

  data() {
    return {
      articleList: [],
      isSubscription: Number(this.$route.query.isSubscription),
    };
  },

  watch: {
    $route: {
      handler(val) {
        this.isSubscription =
          val.query.isSubscription && Number(val.query.isSubscription);
      },
      deep: true,
    },
  },

  mounted() {
    this.getStudentSubscribeResult(getUserId());
    this.queryData();
  },

  methods: {
    ...mapActions(["getStudentSubscribeResult"]),

    queryData() {
      getAllArticles().then((res) => {
        this.articleList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  height: calc(100vh - 90px);
  display: flex;

  .article-list {
    flex: 1;
    margin-right: 20px;
    overflow-y: scroll;
  }

  .subscription-content {
    flex: 1;
    margin-right: 20px;
    overflow-y: scroll;
  }
}
</style>
